<template>
  <a-modal
    :visible="showDeleteOrg"
    centered
    title="Delete Organization"
    okText="Submit"
    @ok="isConfirm = true"
    @cancel="handleCloseDeleteOrg"
    :okButtonProps="{
      id: 'delete-org-ok-btn',
      loading: deletingOrg,
    }"
    :cancelButtonProps="{
      id: 'delete-org-cancel-btn',
      style: { marginLeft: 'auto' },
    }"
    id="org-deletion-modal"
  >
    <template #closeIcon>
      <close-outlined id="delete-org-close-btn" />
    </template>
    <p>
      Are you sure? you want to delete the organization.<br />
      Please enter organization name to delete.
    </p>
    <div class="d-flex flex-column">
      <a-input class="w-50" id="orgDeleteMsg" v-model:value="orgDeleteMsg" />
      <small class="text-danger" v-if="isError"
        >Please enter correct name!</small
      >
    </div>
  </a-modal>

  <a-modal
    v-model:visible="showUpdateDeviceSettings"
    centered
    :closable="false"
    :title="'Update Device Settings'"
    :footer="null"
    destroyOnClose
  >
    <update-device-settings
      @cancel="handleshowUpdateDeviceSettings"
      :orgName="this.orgToUpdateDeviceSettings"
    >
    </update-device-settings>
  </a-modal>

  <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
    <a-input-search
      v-model:value="searchValue"
      @change="handleSearch"
      @keyup.enter="handleSearch"
      placeholder="Search Organization By Name"
    />
  </a-col>

  <a-table
    :columns="orgListColumns"
    :data-source="list"
    bordered
    :pagination="pagination"
    @change="handleTableChange"
    :scroll="{ y: '55vh', x: '900px' }"
  >
    <template #title>
      <a-typography-title :level="5">
        Organizations: &nbsp;
        <a-tag color="blue">{{ organizationCount }}</a-tag>
      </a-typography-title>
    </template>
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'logo'">
        <img
          v-if="!record.notFound"
          :key="'logo_' + record.Org_name"
          :alt="record.Org_name"
          :src="record.logoImage"
          @error="handleImageError(event, record)"
          width="40"
          height="40"
        />
        <span v-else> Not Found </span>
      </template>
      <template v-if="column.dataIndex === 'actions'">
        <div class="actions">
          <a-dropdown :trigger="['click']">
            <a-button class="ml-2">
              <template #icon>
                <MenuOutlined class="menu-icon" />
              </template>
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  key="1"
                  @click="createKaizenBucket(record.Org_name)"
                >
                  <PlusOutlined />
                  Create Kaizen Bucket
                </a-menu-item>
                <a-menu-item
                  key="2"
                  @click="refreshTrainingVideos(record.Org_name)"
                >
                  <RedoOutlined />
                  Referesh Videos
                </a-menu-item>
                <a-menu-item
                  key="3"
                  @click="handleshowUpdateDeviceSettings(record.Org_name)"
                >
                  <SettingOutlined />
                  Update Device Settings
                </a-menu-item>
                <a-menu-item key="4" @click="onEdit(record)">
                  <EditOutlined />
                  Edit
                </a-menu-item>
                <a-menu-item key="5" @click="handleDeleteOrg(record.Org_name)">
                  <DeleteOutlined />
                  Delete
                </a-menu-item>
                <a-menu-item
                  key="6"
                  @click="handleUploadLogoOrg(record.Org_name)"
                >
                  <a-upload
                    accept=".png, .jpg, .jpeg, .ico"
                    :multiple="false"
                    :before-upload="beforeUpload"
                    :custom-request="
                      (fileObj) => handleUploadLogo(fileObj, record)
                    "
                    :show-upload-list="false"
                  >
                    <FileImageOutlined />
                    Upload Logo
                  </a-upload>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  MenuOutlined,
  RedoOutlined,
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SettingOutlined,
  FileImageOutlined,
} from '@ant-design/icons-vue';
import { Upload } from 'ant-design-vue';
import orgListColumns from './config';
import OrgServices from 'src/services/organization';
import UpdateDeviceSettings from './UpdateDeviceSettings.vue';
import S3Service from 'src/services/s3';
import debounce from 'debounce';

export default {
  name: 'OrganizationComponent',
  components: {
    MenuOutlined,
    RedoOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    SettingOutlined,
    FileImageOutlined,
    CloseOutlined,
    UpdateDeviceSettings,
  },
  inject: ['toast'],
  emits: ['onEditClick'],
  setup: () => ({ orgListColumns }),
  data() {
    return {
      uploadLogoOrg: '',
      img_url:
        'https://retrocausal-video-thumbnails.s3.us-east-2.amazonaws.com/logos/',
      list: [],
      totalCount: 0,
      orgDeleteMsg: '',
      searchValue: '',
      orgToDelete: null,
      orgToUpdateDeviceSettings: null,
      showDeleteOrg: false,
      isConfirm: null,
      isError: false,
      deletingOrg: false,
      showUpdateDeviceSettings: false,
    };
  },
  computed: {
    ...mapGetters(['currentOrgPage', 'organizationList', 'organizationCount']),

    pagination() {
      return {
        position: ['bottomCenter'],
        showSizeChanger: false,
        current: this.currentOrgPage,
        total: this.organizationCount,
      };
    },
  },
  watch: {
    async isConfirm(value) {
      if (value === null) return;
      if (value) {
        if (this.orgDeleteMsg !== this.orgToDelete) {
          this.isError = true;
          this.isConfirm = null;
          return;
        }
        this.deleteOrg();
        this.isError = false;
        this.isConfirm = null;
        this.orgDeleteMsg = '';
      } else {
        this.taskUpdateMsg = '';
        this.isError = false;
      }
      this.showDeleteOrg = false;
      this.isConfirm = false;
    },
    organizationList(value) {
      this.list = value;
    },
    currentOrgPage() {
      this.getPaginatedOrganizations(this.searchValue);
    },
  },
  created() {
    this.getPaginatedOrganizations(this.searchValue);
  },
  methods: {
    ...mapActions([
      'deleteSingleOrg',
      'refreshVideos',
      'getPaginatedOrganizations',
      'handlePageChange',
    ]),

    handleshowUpdateDeviceSettings(orgName) {
      this.orgToUpdateDeviceSettings = orgName;
      this.showUpdateDeviceSettings = !this.showUpdateDeviceSettings;
    },

    handleDeleteOrg(orgName) {
      this.orgToDelete = orgName;
      this.showDeleteOrg = true;
    },

    async deleteOrg() {
      this.deletingOrg = true;
      await this.deleteSingleOrg(this.orgToDelete);
      await this.deleteLogoFile(this.orgToDelete);
      this.getPaginatedOrganizations(this.searchValue);
      this.deletingOrg = false;
    },

    onEdit(rowData) {
      this.$emit('onEditClick', rowData);
    },

    beforeUpload(file) {
      const validExtensions = ['png', 'jpg', 'jpeg', 'ico'];
      const extension = file.name.split('.').pop().toLowerCase();

      if (!validExtensions.includes(extension)) {
        this.toast.error('Invalid File');
        return Upload.LIST_IGNORE;
      }
    },

    handleUploadLogoOrg(org_name) {
      this.uploadLogoOrg = org_name;
    },

    async handleUploadLogo(fileObj, obj) {
      const { file } = fileObj;
      var payload = new FormData();
      const bucket = 'retrocausal-video-thumbnails';
      const filePath = `logos/${this.uploadLogoOrg}.jpg`;

      payload.append('file', file);
      payload.append('bucket', bucket);
      payload.append('file_path', filePath);

      const [Error] = await S3Service.uploadFile(payload, false);
      if (Error) {
        this.toast.error('Failed to upload organization logo');
        return;
      }
      this.updateOrgInList(obj.Org_name, {
        logoImage: this.img_url + obj.Org_name + '.jpg?t=' + Date.now(),
        notFound: false,
      });
      this.toast.success('Successfully uploaded organization logo');
    },

    async deleteLogoFile(orgName) {
      const payload = {
        bucket: 'retrocausal-video-thumbnails',
        file_path: `logos/${orgName}.jpg`,
        organization: orgName,
      };
      await S3Service.deleteFile(payload, false);
    },

    handleImageError(event, record) {
      record.notFound = true;
      record.logoImage = '';
    },

    async refreshTrainingVideos(orgName) {
      const res = await this.refreshVideos(orgName);
      if (res === 'error') {
        this.toast.error('Error occured while refreshing videos!');
        return;
      }
      this.toast.success(`${res.body} videos refreshed!`);
    },

    async createKaizenBucket(orgName) {
      const payload = {
        organization: orgName,
      };
      const [error, data] = await OrgServices.createKaizenBucket(payload);
      if (error) {
        const errMsg = error.response.data['response'];
        this.toast.info(errMsg);
        return;
      }
      this.toast.success(data.response);
    },

    handleTableChange(pagination) {
      const { current } = pagination;
      this.handlePageChange(current);
    },

    updateOrgInList(orgName, updatedObj) {
      const temp = [...this.list];
      const index = temp.findIndex((v) => v.Org_name === orgName);
      if (temp[index]) {
        temp[index] = {
          ...temp[index],
          ...updatedObj,
        };
      }
      this.list = temp;
    },

    handleCloseDeleteOrg() {
      this.isConfirm = false;
      this.orgDeleteMsg = '';
    },

    handleSearch: debounce(async function () {
      if (this.currentOrgPage === 1)
        this.getPaginatedOrganizations(this.searchValue);
      else this.handlePageChange(1);
    }, 500),
  },
};
</script>

<style scoped>
.actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 6px;
}

.error-msg {
  color: red;
}

.error-box {
  width: 140px;
  margin-left: 20px;
}
</style>
