<template>
  <a-form
    layout="vertical"
    :model="form"
    ref="formRef"
    name="add_org"
    :rules="validationsRules()"
    :validate-messages="validateMessages()"
    class="org-form"
    @finish="onSubmit"
  >
    <div class="row">
      <div class="col-sm-6">
        <a-form-item label="Organization Name" name="name">
          <a-input
            v-model:value="form.name"
            placeholder="Enter Name"
            :disabled="edit"
          >
          </a-input>
        </a-form-item>
      </div>

      <div class="col-sm-6">
        <a-form-item label="Address" name="address">
          <a-input v-model:value="form.address" placeholder="Enter Address">
          </a-input>
        </a-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <a-form-item label="Employees" name="employee" class="w-100">
          <a-input-number
            id="inputNumber"
            style="width: 220px"
            placeholder="Enter Number of Employee"
            v-model:value="form.employee"
            :min="0"
            :max="10000"
          />
        </a-form-item>
      </div>

      <div class="col-sm-6">
        <a-form-item label="Cap FPS" name="fps_cap" class="w-100">
          <a-input-number
            id="inputNumber"
            style="width: 220px"
            placeholder="Enter FPS Cap"
            v-model:value="form.fps_cap"
            :min="10"
            :max="30"
          />
        </a-form-item>
      </div>

      <div class="col-sm-6">
        <a-form-item label="Description" name="desc" autocomplete="off">
          <a-input v-model:value="form.desc" placeholder="Enter Description" />
        </a-form-item>
      </div>
    </div>

    <div class="row" v-if="!edit">
      <div class="col-sm-6">
        <a-form-item name="logo" class="w-100">
          <a-upload
            accept=".png, .jpg, .jpeg, .ico"
            :multiple="false"
            :before-upload="beforeUpload"
            :customRequest="handleCustomRequest"
            :show-upload-list="true"
            listType="picture"
            :maxCount="1"
          >
            <a-button> Upload Organization Logo </a-button>
          </a-upload>
        </a-form-item>
      </div>
    </div>

    <div class="text-right">
      <a-button @click="onCancel">
        Cancel
      </a-button>
      <a-button
        type="primary"
        html-type="submit"
        :loading="isLoading"
        class="ml-2"
      >
        {{ edit === true ? 'Update' : 'Create' }}
      </a-button>
    </div>
  </a-form>
</template>
<script>
import { mapActions } from 'vuex';
import { Upload } from 'ant-design-vue';
import S3Service from 'src/services/s3';

export default {
  inject: ['toast'],
  props: ['initialValues', 'edit'],
  emits: ['cancel', 'resetFormValues'],
  data() {
    return {
      isLoading: false,
      Org_name: '',
      logo: null,
      form: { ...this.initialValues }
    };
  },
  watch: {
    initialValues(value) {
      this.form = { ...value };
      this.Org_name = value.name;
    }
  },
  methods: {
    ...mapActions([
      'addNewOrg',
      'updateSingleOrg',
      'handlePageChange',
      'getPaginatedOrganizations'
    ]),
    validateMessages() {
      return {
        required: '${label} is required',
        pattern: {
          mismatch: 'Lowercase letters, digits, and hyphens are allowed.'
        }
      };
    },
    validationsRules() {
      return {
        name: [
          {
            required: true,
            trigger: '[submit,cancel]',
            pattern: /^[a-z0-9-]+$/
          }
        ],
        address: [
          {
            required: true,
            trigger: '[submit,cancel]'
          }
        ],
        employee: [
          {
            required: true,
            trigger: '[submit,cancel]'
          }
        ],
        fps_cap: [
          {
            required: true,
            trigger: '[submit,cancel]'
          }
        ],
        desc: [
          {
            required: true,
            trigger: '[submit,cancel]'
          }
        ]
      };
    },

    async onSubmit() {
      this.isLoading = true;
      const data = {
        Org_name: this.form.name,
        Number_of_emp: this.form.employee,
        Description: this.form.desc,
        Address: this.form.address,
        fps_cap: this.form.fps_cap
      };
      (await this.edit) ? this.updateOrg(data) : this.addOrg(data);
    },

    async addOrg(reqBody) {
      const [error, repsonse] = await this.addNewOrg(reqBody);
      if (error) {
        const errorResponse = error.response.data;
        if (
          errorResponse['Org_name'] &&
          errorResponse['Org_name']?.[0]?.includes('already exists')
        ) {
          this.toast.error(
            `Organization with the name '${this.form.name}' already exists`
          );
        } else this.toast.error('Something went wrong, Please try again!');
      } else {
        this.toast.success('Organization added successfully!');
        if (this.logo !== null) await this.UploadLogoS3();
      }

      this.isLoading = false;
      this.onCancel();
      this.$emit('resetFormValues');
      this.handlePageChange(1);
      this.getPaginatedOrganizations();
    },

    async UploadLogoS3() {
      var payload = new FormData();
      const bucket = 'retrocausal-video-thumbnails';
      const filePath = `logos/${this.form.name}.jpg`;

      payload.append('file', this.logo);
      payload.append('bucket', bucket);
      payload.append('file_path', filePath);

      const [Error] = await S3Service.uploadFile(payload, false);
      if (Error) {
        this.toast.error('Failed to upload organization logo');
      } else {
        this.toast.success('Successfully uploaded organization logo');
      }
      this.logo = null;
    },

    beforeUpload(file) {
      const validExtensions = ['png', 'jpg', 'jpeg', 'ico'];
      const extension = file.name
        .split('.')
        .pop()
        .toLowerCase();

      if (!validExtensions.includes(extension)) {
        this.toast.error('Invalid File');
        return Upload.LIST_IGNORE;
      }
      return true;
    },

    async updateOrg(reqBody) {
      const response = await this.updateSingleOrg({
        id: this.form.name,
        ...reqBody
      });
      this.isLoading = false;
      if (!response) return;

      this.onCancel();
      this.getPaginatedOrganizations();
      this.$emit('resetFormValues');
    },

    onCancel() {
      this.$emit('cancel');
      this.$emit('resetFormValues');
      this.logo = null;
    },

    handleCustomRequest(option) {
      this.logo = option.file;
      setTimeout(() => {
        option.onProgress({ percent: 100 });
        option.onSuccess();
      }, 0);
    }
  }
};
</script>

<style scoped>
.center {
  display: flex;
  align-items: center;
}
.pre-icon {
  font-size: 24px;
  color: #047bf8;
}
</style>
